import { Popover, Transition } from "@headlessui/react";
import { render } from "@testing-library/react";
import Prices from "components/Prices";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import CartContext from "Context/CartContext";
import StepsContext from "Context/StepsContext";
import TotalContext from "Context/TotalContext";
import { Product, PRODUCTS } from "data/data";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DeleteCard } from "Services/API/Delete";
import { getCartList } from "Services/API/Get";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LoadingSpinner from "shared/LoadingSpinner";

export default function CartDropdown() {
  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);



  const [array, setArray] = useState([]);
  const [Total, setTotal] = useState<any>(0);
  const [lopen, setlopen] = useState(false);


  React.useEffect(() => {
    getCart();

  }, [cart, cartotal]);




  const getCart = () => {
    getCartList()
      .then((res) => {
        // console.log("get Cart >>>>>", res.data)
        let cartTotalPrice = 0;
        const abc = res.data?.map((e: any) => {
          const finalProductPrice = e.unitprice?.toFixed(2);

          cartTotalPrice += finalProductPrice * e.qty
          // m = m + e.qty * e.discountedprice;
        });
        setArray(res.data);
        setTotal(cartTotalPrice);
        setlopen(false);
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        setArray([]);
        setTotal(0);
        setlopen(false);
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  }


  const renderProduct = (item: any, index: number, close: () => void) => {
    const { name, price, image } = item;

    const thumblineImage = JSON.parse(
      item.images
    ).filter((item: any) => item.thumbnail === true)[0];


    return (
      <div key={index} className="flex py-5 last:pb-0">
        <div className="relative h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={image}
            src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
            alt={item?.productName}
            className="h-full w-full object-contain object-center"
          />
          <Link
            onClick={close}
            className="absolute inset-0"
            to={`/product-detail/${item?.productId}`}
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-sm font-medium ">
                  <Link onClick={close} to={`/product-detail/${item?.productId}`}>
                    {item?.productName}
                  </Link>
                </h3>
                <p className="mt-1 text-xs text-slate-500 dark:text-slate-400">
                  <span className="capitalize">{item?.selectedProductColor}</span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{item?.selectedProductSize}</span>
                </p>
              </div>
              <Prices price={item?.unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-xs">
            <p className="text-gray-500 dark:text-slate-400">{`Qty ${item?.qty}`}</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-[#74BC1F] "
                onClick={() => {
                  setlopen(true);
                  DeleteCard(item?.cartId)
                    .then((res) => {
                      CartCount().then((res) => {
                        // console.log("count res-------->", res);
                        setCart(res);
                      });
                      // addToast(`${single.productName} Removed`, { appearance: "error", autoDismiss: true });
                      getCart();

                      //  this.setState(
                      //    {
                      //      open_toast: true,
                      //      alertClass: "error",
                      //      msg: `${data.displayName} Removed`,
                      //      color: "#635bff",
                      //    },
                      //    () => this.getAllCart()
                      //  );
                    })
                    .catch((error) => {
                      setlopen(false);
                      console.error("delete cart item error", error);
                    }

                    );
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };



  return (
    <>
      <LoadingSpinner open={lopen} />
      <Popover className="relative">

        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              <div className="w-3.5 h-3.5 flex items-center justify-center bg-[#74BC1F] absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
                <span className="mt-[1px]">{cart}</span>
              </div>
              <svg
                className="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 8H21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <Link className="block md:hidden absolute inset-0" to={"/cart"} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-28 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                  <div className="relative bg-white dark:bg-neutral-800">
                    <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                      <h3 className="text-lg font-semibold">Shopping cart</h3>
                      <div className="divide-y divide-slate-100 dark:divide-slate-700">
                        {/* {array.map(
                        (item, index) => renderProduct(item, index, close)
                      )} */}
                        {array.map((single: any, key: any) => {



                          return (

                            renderProduct(single, key, close)
                          );
                        })}
                      </div>
                    </div>
                    {
                      array.length > 0 ?

                        <>
                          <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                            <p className="flex justify-between  text-slate-900 dark:text-slate-100">
                              <span className="flex flex-col">
                                {/* <span>Subtotal</span> */}
                                {/* <span className="mt-1">Shipping</span>
                                <span className="mt-1">Tax (10% GST)</span> */}
                                <span className="mt-2 font-semibold">Total</span>
                                <span className="block text-xs text-slate-500 dark:text-slate-400 font-normal mt-1">
                                  Shipping and taxes calculated at checkout.
                                </span>
                              </span>
                              <span className="flex flex-col">
                                {/* <span className="">{`$ ${Total.toFixed(2)}`}</span> */}
                                {/* <span className="mt-1">{`$ ${16.95}`}</span>
                                <span className="mt-2 font-semibold">{`$ ${((Total + 16.95) * 10 / 100).toFixed(2)}`}</span> */}
                                <span className="mt-2 font-semibold">{`$ ${(Total).toFixed(2)}`}</span>
                              </span>
                            </p>
                            <div className="flex space-x-2 mt-5">
                              <ButtonSecondary
                                href="/cart"
                                className="flex-1 border border-slate-200 dark:border-slate-700"
                                onClick={close}
                              >
                                View cart
                              </ButtonSecondary>
                              <ButtonPrimary
                                href="/checkout"
                                onClick={close}
                                className="flex-1"
                              >
                                Check out
                              </ButtonPrimary>
                            </div>
                          </div>

                        </>
                        :
                        <>
                          <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                            <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                              <span>
                                {/* <span>Your cart is empty</span> */}
                                <span className="block text-xs text-slate-500 dark:text-slate-400 font-normal">
                                  No items added to cart
                                </span>
                              </span>
                              {/* <span className="">{`$ ${Total.toFixed(2)}`}</span> */}
                            </p>
                            {/* <div className="flex space-x-2 mt-5">
                            <ButtonSecondary
                              href="/cart"
                              className="flex-1 border border-slate-200 dark:border-slate-700"
                              onClick={close}
                            >
                              View cart
                            </ButtonSecondary>
                            <ButtonPrimary
                              href="/checkout"
                              onClick={close}
                              className="flex-1"
                            >
                              Check out
                            </ButtonPrimary>
                          </div> */}
                          </div>

                        </>
                    }

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

    </>

  );

}
