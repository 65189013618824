import Label from "components/Label/Label";
import React, { FC, useState, useEffect, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { avatarImgs } from "contains/fakeData";
import { EditUserQuery, UpdateUserwithemail } from "Services/API/Put";
import { signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from 'lodash';
import { addToast } from "shared/Toast";
import { CurrentUser, getCities, getDistricts, getSignUpVerification } from "Services/API/Get";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import AuthContext from "Context/AuthContext";
import LoadingSpinner from "shared/LoadingSpinner";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { FONTS_INTER } from "Constant";
import { COLORS } from "Constant/Colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option': {
      width: "100%"
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "#e2e8f0 !important"
    }
  },
  root: {
    "& .MuiOutlinedInput-root": {
      padding: 7,
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: "0px",
      fontFamily: FONTS_INTER,
      fontSize: "10.5pt",

      color: COLORS.TextColor2,
      fontWeight: 400,
      outline: "none",
      border: "none",

      height: "1.6rem",
    },
    '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
      opacity: 1,
      WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
      fontWeight: "400",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",

      background: "none",
    },
  },
}));

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const location = useLocation()
  const path = location.pathname

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.PrimaryBackground,
      },
      secondary: {
        main: COLORS.PrimaryBackground,
      },
    },
  });

  const history = useHistory();
  const classes = useStyles();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const { user, setUser } = useContext(AuthContext);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [lopen, setlopen] = React.useState(false);

  const [homeNo, sethomeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");

  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);

  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");

  const [userId, setuserId] = useState("");
  const [currUser, setcurrUser] = useState<any>("");
  const [eDisable, seteDisable] = useState(true);
  const [eDisable2, seteDisable2] = useState(true);

  const onSubmit = async (e?: any) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }

    // if (homeNo === "") {
    //   seterrhomeNo(true);
    // }
    // if (mobile === "") {
    //   seterrmobile(true);
    // }
    // if (password !== "") {
    //   //   seterrPass(true)
    //   if (repassword === "") {
    //     seterrRePass("This Field is Requied");
    //   } else {
    //     seterrRePass("");
    //   }
    // }


    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }


    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(firstName)),
      lastName: upperFirst(lowerCase(lastName)),
      mobileNumber: mobile,
      email: email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: currUser.homeAddress === null ? "" : currUser.homeAddress,
      homeCity: currUser.homeCity === null ? "" : currUser.homeCity,
      password: "",
      homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,
      homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
      note: currUser.note === null ? "" : currUser.note,

    };
    // console.log("obj>>>>", obj);

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&

      mobile !== "" &&
      // (password !== "" ? repassword !== "" : firstName !== "") &&
      phoneRegex.test(mobile)


    ) {
      setlopen(true);

      if (email !== premail) {

        let obj2 = {
          data: {
            firstName: upperFirst(lowerCase(firstName)),
            lastName: upperFirst(lowerCase(lastName)),
            mobileNumber: mobile,
            email: email,
            homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,

            homeAddress: currUser.homeAddress === null ? "" : currUser.homeNumber,

            homeCity: currUser.homeCity === null ? "" : currUser.homeCity,



            password: "",
            // oldPassword: repassword,

            homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,

            homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
            note: currUser.note === null ? "" : currUser.note,

            type: "profile",
          },
        };

        signUpVerification(obj2)
          .then((res) => {
            setlopen(false);
            addToast(`The email verification link has been sent to ${email}. 
          Please click on the confirmation link to confirm your email. `, "success");
            // setuserId(res.data[0]);
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg(
            //   `The email verification link has been sent to ${email}. 
            //   Please click on the confirmation link to confirm your email. `
            // );
            // setTimeout(() => {
            //   //  setOpen(true);
            //   history.push("/login");
            //   window.location.reload();
            // }, 2000);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            console.log("Error", error.response);
            addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });


      } else {
        EditUserQuery(obj)
          .then((res) => {
            setlopen(false);
            addToast("Update Successful", "success");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Update Successful");
            getCurrentUser();
            seteDisable(true);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            console.log("Error", error.response);
            addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })
      }

    } else {
      // scrollElement();
    }
  };

  const onSubmit2 = async (e?: any) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }

    // if (password === "") {
    //   seterrPass(true)

    // }

    // if (repassword === "") {
    //   seterrRePass("This Field is Requied");

    // }

    // if (password !== "") {
    //   //   seterrPass(true)
    //   if (repassword === "") {
    //     seterrRePass("This Field is Requied");
    //   } else {
    //     seterrRePass("");
    //   }
    // }





    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(currUser.firstName)),
      lastName: upperFirst(lowerCase(currUser.lastName)),
      mobileNumber: currUser.mobileNumber,
      email: currUser.email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: homeAddress,
      homeCity: homeCity,
      password: "",

      homeDistrict: homeDistrict,
      homepostCode: homepostCode,
      note: currUser.note === null ? "" : currUser.note,

    };
    // console.log("obj>>>>", obj);

    if (

      homeAddress !== "" &&
      homeCity !== "" &&
      homeDistrict !== "" &&
      homepostCode !== ""
      // (password !== "" ? repassword !== "" : false)


    ) {
      setlopen(true);

      EditUserQuery(obj)
        .then((res) => {
          setlopen(false);
          addToast("Update Successful", "success");
          setPassword("");
          setrePassword("");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Update Successful");
          getCurrentUser();
          seteDisable2(true);
          // seteDisable(true);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        })


    } else {
      // scrollElement();
    }
  };

  const getVerification = () => {

    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setlopen(true);
      getSignUpVerification(token)
        .then((res) => {
          UpdateUserwithemail(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast("Update Successful", "success");
              getCurrentUser();
              history.push("/account");

              // setTimeout(() => {
              //   history.push("/account");
              //   window.location.reload();
              // }, 2000);

            })
            .catch((error) => {
              // setlopen(false);
              console.log("Error", error.response);
              setlopen(false);
              addToast(error.response.data.message, "warning");
            });
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setlopen(false);
          addToast(error.response.data.message, "warning");
        });
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          // console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setpremail(cData?.email);
          setmobile(cData?.mobileNumber);
          sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          setuserId(cData?.id);

          getDistricts()
            .then((res) => {

              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });



          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
    getVerification();
    listDistricts();

  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        // console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        // console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    // console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeCity(value.fields.scc_name);
      sethomepostCode(value.fields.scc_code);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    // console.log("city>>", value);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Oz Cleaning Supplies </title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path+"/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-xl sm:text-2xl font-semibold">
            Billing Details
          </h2>
          <div className="flex flex-col md:flex-row">
            {/* <div className="flex-shrink-0 flex items-start">
              
              <div className="relative rounded-full overflow-hidden flex">
                <img
                  src={avatarImgs[2]}
                  alt=""
                  className="w-32 h-32 rounded-full object-cover z-0"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-[10px]">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div> */}
            <div className="flex-grow mt-10 md:mt-0 md:pl-0 max-w-3xl space-y-6">
              {/* <div>
                <Label>First Name</Label>
                <Input className="mt-1.5"
                  placeholder="First Name"
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    seterrfirstName(false);
                  }}
                  value={firstName}

                />
                {errfirstName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div> */}

              {/* <div>
                <Label>Last Name</Label>
                <Input className="mt-1.5"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setlasttName(e.target.value);
                    seterrlasttName(false);
                  }}
                  value={lastName}

                />
                {errlastName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div> */}

              {/* ---- */}

              {/* ---- */}
              {/* <div>
                <Label>Email</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs">
                    <i className="text-xl las la-envelope"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    placeholder="example@email.com"
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                    value={email}
                  />
                  
                </div>
                {erremail && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div> */}

              {/* ---- */}
              {/* <div className="max-w-lg">
                <Label>Date of birth</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs">
                    <i className="text-xl las la-calendar"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    type="date"
                    defaultValue="1990-07-22"
                  />
                </div>
              </div> */}
              {/* ---- */}
              <div>
                <Label>Addess</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs">
                    <i className="text-xl las la-map-signs"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    placeholder="New york, USA"
                    onChange={(e) => {
                      sethomeAddress(e.target.value);
                      seterrhomeAddress(false);
                    }}
                    value={homeAddress}
                  />
                </div>
                {errhomeAddress && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div>
                <Label>State</Label>


                <Autocomplete
                  className="mt-1.5"
                  // className="form-group"
                  // className="border-neutral-200"
                  disablePortal
                  defaultValue={{ name_en: homeDistrict }}
                  value={{ name_en: homeDistrict }}

                  // id="combo-box-demo"
                  options={allHDistricts}
                  getOptionLabel={(option) => option.name_en}
                  onChange={(event, value) => {

                    handleChangeHdist(event, value);
                    // setCountry(value);
                  }}
                  // sx={{
                  //   // width: "80vw",
                  //   background:"transparent",
                  //   borderRadius: "20px",
                  //   // border: "none",
                  //   // borderColor: "transparent",

                  //   "& .MuiInputBase-input": {
                  //     height: 15,
                  //     // border: "none",
                  //     fontSize: "14px",
                  //     fontFamily: "'Jost', sans-serif",
                  //     // borderColor: "transparent",
                  //   },
                  //   //view
                  //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  //     // borderColor: "transparent",
                  //   },

                  //   "&:hover .MuiOutlinedInput-input": {
                  //     color: "black",
                  //   },
                  //   "&:hover .MuiInputLabel-root": {
                  //     color: COLORS.GrayBackground,
                  //   },
                  //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  //   {
                  //     // borderColor: "transparent",
                  //   },
                  //   ".MuiAutocomplete-root fieldset:hover": {
                  //     color: "transparent",
                  //     // borderColor: "transparent",
                  //   },
                  // }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <Input {...params.inputProps} className="" placeholder="Select State" />
                    </div>
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                      fontSize: "14px",
                      // border: '1px solid white'
                    },
                  }}
                />

                {errhomeDistrict && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}

              </div>

              <div>
                <Label>Suburb / City</Label>


                <Autocomplete
                  className="mt-1.5"
                  // className="form-group"
                  // className="border-neutral-200"
                  disablePortal
                  defaultValue={{ fields: { scc_name: homeCity } }}
                  value={{ fields: { scc_name: homeCity } }}
                  // id="combo-box-demo"
                  options={allHCities}
                  getOptionLabel={(option) => option?.fields?.scc_name}
                  onChange={(event, value) => {

                    handleChangeHcity(event, value);
                    // setCountry(value);
                  }}
                  // sx={{
                  //   // width: "80vw",
                  //   background:"transparent",
                  //   borderRadius: "20px",
                  //   // border: "none",
                  //   // borderColor: "transparent",

                  //   "& .MuiInputBase-input": {
                  //     height: 15,
                  //     // border: "none",
                  //     fontSize: "14px",
                  //     fontFamily: "'Jost', sans-serif",
                  //     // borderColor: "transparent",
                  //   },
                  //   //view
                  //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  //     // borderColor: "transparent",
                  //   },

                  //   "&:hover .MuiOutlinedInput-input": {
                  //     color: "black",
                  //   },
                  //   "&:hover .MuiInputLabel-root": {
                  //     color: COLORS.GrayBackground,
                  //   },
                  //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  //   {
                  //     // borderColor: "transparent",
                  //   },
                  //   ".MuiAutocomplete-root fieldset:hover": {
                  //     color: "transparent",
                  //     // borderColor: "transparent",
                  //   },
                  // }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <Input {...params.inputProps} className="" placeholder="Select State" />
                    </div>
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                      fontSize: "14px",
                      // border: '1px solid white'
                    },
                  }}
                />

                {errhomeCity && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}

              </div>


              <div>
                <Label>Post Code</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs">
                    <i className="text-xl las la-envelope"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    placeholder="1152"
                    onChange={(e) => {
                      sethomepostCode(e.target.value);
                      seterrhomepostCode(false);
                      // seterrfirstName(false);
                    }}
                    value={homepostCode}
                  />
                </div>
                {errhomepostCode && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              {/* ---- */}
              {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}

              {/* ---- */}
              {/* <div>
                <Label>Phone number</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs">
                    <i className="text-xl las la-phone-volume"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    // defaultValue="003 888 232"
                    placeholder="Enter Mobile"
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile("");
                    }}
                    value={mobile}
                  />
                </div>
                {errmobile && (
                  <div className="text-red-600 text-xs text-right mt-2">
                   {errmobile}
                  </div>
                )}
              </div> */}
              {/* ---- */}
              {/* <div>
                <Label>About you</Label>
                <Textarea className="mt-1.5" defaultValue="..." />
              </div> */}
              <div className="pt-2">
                <ButtonPrimary onClick={onSubmit2}>Update account</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
