import Prices from "components/Prices";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { PRODUCTS } from "data/data";
import moment from "moment";
import React, { useState } from "react";
import { getOrderHistory } from "Services/API/Get";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LoadingSpinner from "shared/LoadingSpinner";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AccountOrder = () => {
  const location = useLocation();
  const path = location.pathname;

  const [array, setArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [lopen, setlopen] = useState(false);

  React.useEffect(() => {
    getCart();
  }, []);

  const getCart = () => {
    setlopen(true);
    getOrderHistory()
      .then((res) => {
        setlopen(false);
        console.log("get Cart page>>>>>", res);
        let m = 0;
        const abc = res.data?.map((e: any) => {
          m = m + e.qty * e.discountedprice;
        });
        setArray(res.data);
        setTotal(m);
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((error) => {
        console.error(error);
        setlopen(false);
        setArray([]);
      });
  };

  const renderProductItem = (product: any, index: number) => {
    const { image, name } = product;

    const thumblineImage = JSON.parse(product.image).filter(
      (item: any) => item.thumbnail === true
    )[0];

    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
            alt={product.productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-sm font-medium line-clamp-1">
                  {product.productName}
                </h3>
                <p className="mt-1 text-xs text-slate-500 dark:text-slate-400">
                  <span className="capitalize">
                    {JSON.parse(product.productDetails)[0].colour}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span className="capitalize">
                    {JSON.parse(product.productDetails)[0].size}
                  </span>
                </p>
              </div>
              <Prices price={product?.productPrice} className="mt-0.5 ml-2" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-xs">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Qty</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2"> {product.qty}</span>
            </p>

            {/* <div className="flex">
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Leave review
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (cartItem: any, index: number) => {
    console.log(">><><><", cartItem);
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-base font-semibold">
              {/* #WU3746HGG12 */}
              {cartItem?.orderNo}
            </p>
            <p className="text-slate-500 dark:text-slate-400 text-xs mt-1.5 sm:mt-2">
              <span>
                {/* Aug 8, 2023 */}
                {moment(cartItem.order[0].createdAt).format("MMM DD, yyyy")}
              </span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">{`$ ${cartItem.order[0].amountPayable.toFixed(
                2
              )}`}</span>
            </p>
          </div>
          {/* <div className="mt-3 sm:mt-0">
            <ButtonSecondary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-xs font-medium"
            >
             {`$ ${cartItem.order[0].amountPayable.toFixed(2)}`}
            </ButtonSecondary>
          </div> */}
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {cartItem.order.map(renderProductItem)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          Orders | Cleaning supplies Dandenong - Oz Cleaning Supplies
        </title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-xl sm:text-2xl font-semibold">Order History</h2>
          {array.length > 0 ? (
            array.map(renderOrder)
          ) : (
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No Orders Found
                </p>
              </div>
              <div className="flex  justify-center items-center">
                <a href="/shop">
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>
            </>
          )}
          {/* {renderOrder()} */}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
