import React from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { Helmet } from "react-helmet";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCardNew from "components/SectionSliderProductCardNew";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionPromo from "components/SectionPromo1";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories2";
import SectionSliderCategoriesNew from "components/SectionSliderCategories/SectionSliderCategoriesNew";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionGridFeatureItemsNew from "./SectionGridFeatureItemsNew";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import { Categories, getAllProducts } from "Services/API/Get";
import department1Png from "images/collections/department1.png";
import department2Png from "images/collections/department2.png";
import department3Png from "images/collections/department3.png";
import department4Png from "images/collections/department4.png";
import { useHistory, useLocation } from "react-router-dom";
import { addToast } from "shared/Toast";
import { setSiteVisits } from "Services/API/Put";
import _ from "lodash";
import category1 from "images/solutions/1.png";
import category2 from "images/solutions/2.png";
import category3 from "images/solutions/3.png";
import category4 from "images/solutions/4.png";
import category5 from "images/solutions/5.png";
import category6 from "images/solutions/6.png";
import category7 from "images/solutions/7.png";

const DATA = [
  {
    name: "BROOMS & DUSTPANS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "BRUSHWARE",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "BUCKETS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "CARTS & TROLLEYS",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "CHEMICALS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "DUST CONTROL",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "HARD FLOOR CARE & PADS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "GARBAGE BAGS & BINS",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "MACHINERY",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "MATTING",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "MOPS & POLISH SPREADERS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "SAFETY",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "VACUUMS & PARTS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "WASHROOM SUPPLIES",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "WINDOW CLEANING",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "WIPES, CLOTHS & MORE",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  }
];

const DATA2 = [
  {
    name: "AGED & HEALTH CARE",
    desc: "20+ categories",
    image: category1,
    color: "bg-indigo-100"
  },
  {
    name: "EDUCATION",
    desc: "10+ categories",
    image: category2,
    color: "bg-slate-100"
  },
  {
    name: "FOOD & DAIRY MANUFACTURING",
    desc: "34+ categories",
    image: category7,
    color: "bg-sky-100"
  },
  {
    name: "HOSPITALITY",
    desc: "12+ categories",
    image: category3,
    color: "bg-orange-100"
  },
  {
    name: "INDUSTRIAL & WAREHOUSE",
    desc: "20+ categories",
    image: category5,
    color: "bg-indigo-100"
  },
  {
    name: "OFFICE & RETAIL",
    desc: "10+ categories",
    image: category4,
    color: "bg-slate-100"
  },
  {
    name: "WINDOW CLEANING",
    desc: "34+ categories",
    image: category6,
    color: "bg-sky-100"
  }
];

function PageHome() {
  const history = useHistory();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const [allProducts4, setallProducts4] = React.useState([]);
  const [allProducts, setallProducts] = React.useState([]);
  const [allProducts2, setallProducts2] = React.useState([]);
  const [cKey, setcKey] = React.useState("all");
  const [allProducts5, setallProducts5] = React.useState<any>([]);

  React.useEffect(() => {
    let pQuery = query.get("pricing");

    let sQuery = query.get("payment");

    // if (pQuery === "true") {
    //   // setlOpen(true);
    //   // setTimeout(() => {
    //   // }, 2000);
    //   scrollToElement2();
    //   setlOpen(false);
    //   history.push("/home");
    //   // scrollToElement2();
    // }

    if (sQuery === "true") {
      // setlOpen(true);
      // setTimeout(() => {
      // }, 2000);
      // setSteps(6);
      localStorage.setItem("order", "true");
      // setopenSuccess(true);
      addToast("Order Successfully Placed !", "success", 8000);
      history.push("/");
      // scrollToElement2();
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const getNewArrival2 = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "productId",
        sort: "asc"
      },
      pNo: "1",
      //   limit: sCategory !== "all" ? "16" : Number.MAX_SAFE_INTEGER.toString(),
      limit: "16",
      latest: "1",
      // latest: 1,

      manufacturer: JSON.stringify([])
      //   categories: sCategory !== "" && sCategory !== "all" ? JSON.stringify([sCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        console.log("this is from new arrival before", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts4(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts4([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival3 = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "productId",
        sort: "asc"
      },
      pNo: "1",
      //   limit: sCategory !== "all" ? "16" : Number.MAX_SAFE_INTEGER.toString(),
      limit: "16",
      featured: "1",
      // latest: 1,

      manufacturer: JSON.stringify([])
      //   categories: sCategory !== "" && sCategory !== "all" ? JSON.stringify([sCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        // console.log("this is from new arrival before", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts2(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts2([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "productId",
        sort: "asc"
      },
      pNo: "1",
      limit: "12",
      // latest: 1,

      manufacturer: JSON.stringify([]),
      categories:
        cKey !== "" && cKey !== "all"
          ? JSON.stringify([cKey])
          : JSON.stringify([])
    })
      .then((res) => {
        // console.log("this is from new arrival before2222", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival5 = async () => {
    Categories()
      .then(async (response) => {
        // console.log("category>>>>", response?.data);

        var cats = response?.data?.filter(function (el: any) {
          return el.maincategory === "Industry Solutions";
        });

        // console.log("CATS>>>", cats);

        const selectedCat = cats[0].mainId;

        const ob = {
          sort: {
            sortField: "productName",
            sort: "asc"
          },
          categories: JSON.stringify([selectedCat]),
          distinct: "0"
        };

        await getAllProducts(ob).then((res) => {
          // console.log("FFF>>", res);
          const allColors = _.uniqBy(
            res?.data?.result,
            function (e: { clolour: any }) {
              return e.clolour;
            }
          );
          var allColors2 = allColors?.filter(function (el: any) {
            return el.clolour !== "";
          });
          const allSizes = _.uniqBy(
            res?.data?.result,
            function (e: { size: any }) {
              return e.size;
            }
          );
          var sizeArr = allSizes?.filter(function (el: { size: string }) {
            return el.size !== "";
          });
          const allPrice = _.uniqBy(
            res?.data?.result,
            function (e: { discountedprice: any }) {
              return e.discountedprice;
            }
          );
          const allTags = _.uniqBy(
            res?.data?.result,
            function (e: { tags: any }) {
              return e.tags;
            }
          );
          const allSubs = _.uniqBy(
            res?.data?.result,
            function (e: { subcatId: any; subCategory: any; subcatDesc: any }) {
              return e.subCategory;
            }
          );

          // console.log("WWEDSD", allSubs);

          var subsArr = allSubs?.filter(function (el: { subCategory: null }) {
            return el.subCategory !== null;
          });
          const myPrices = _.map(allPrice, "discountedprice");

          const Pricelist = _.sortBy(myPrices, [
            function (o: any) {
              return o;
            }
          ]);

          const mySizes = _.map(sizeArr, "size");
          const myColors = _.map(allColors2, "clolour");
          const myTags = _.map(allTags, "tags");
          const mySubs = _.map(subsArr, "subCategory");

          const updateMyColors = myColors?.map((item: any, index: any) => {
            return {
              id: index,
              name: item,
              status: false
            };
          });

          const updateMySubs = subsArr.map((item, index) => {
            let img = "";
            switch (`${item?.subCategory}`.trim().toUpperCase()) {
              case "AGED & HEALTH CARE":
                img = category1;
                break;
              case "EDUCATION":
                img = category2;
                break;
              case "FOOD & DAIRY MANUFACTURING":
                img = category7;
                break;
              case "HOSPITALITY":
                img = category3;
                break;
              case "INDUSTRIAL & WAREHOUSE":
                img = category5;
                break;
              case "OFFICE & RETAIL":
                img = category4;
                break;
              case "WINDOW CLEANING":
                img = category6;
                break;

              // case :
              //   img = category1;
              //   break;
              // case :
              //   img = category1;
              //   break;
            }

            return {
              id: item.subcatId,
              name: item.subCategory,
              mainId: selectedCat,
              subcatId: item.subcatId,
              image: img
              // description: item.subcatDesc,
            };
          });
          // console.log("subs>>>>>", updateMySubs);
          // setallSizes(mySizes);
          // setallColours(myColors);
          // settags(myTags);
          // setallSubs(updateMySubs);

          setallProducts5(updateMySubs);

          // this.setState({
          //   color: updateMyColors,
          //   size: mySizes,
          //   min: Pricelist[0],
          //   max: Pricelist[Pricelist.length - 1],
          //   range: {
          //     min: Pricelist[0],
          //     max: Pricelist[Pricelist.length - 1],
          //   },
          // });
        });
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    if (cKey !== "all") {
      // console.log("VVCBF>>>", cKey);
      getNewArrival();
    }
  }, [cKey]);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getNewArrival2();
      const data2 = await getNewArrival();
      const data3 = await getNewArrival3();
      const data4 = await getNewArrival5();
    };

    // call the function
    fetchData();
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Cleaning supplies Dandenong - Oz Cleaning Supplies</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href="/" />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/"
}
    `}</script>
      </Helmet>

      {/* SECTION HERO */}
      <SectionHero2 />

      {/* <div className="mt-24 lg:mt-32">
        <DiscoverMoreSlider />
      </div> */}

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}

        {/*  */}
        <SectionSliderCategories heading={"Cleaning Brands"} />

        {/*  */}
        <SectionSliderCategoriesNew
          type={"prod"}
          data2={DATA}
          heading="Welcome to Oz Cleaning Supplies"
          subHeading="Your One-Stop Destination for Quality Cleaning Supplies in Dandenong!"
        />
      </div>
      {/*  */}
      <SectionPromo />

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {allProducts4.length > 0 && (
          <SectionSliderProductCardNew
            data2={allProducts4}
            data={[
              PRODUCTS[4],
              SPORT_PRODUCTS[5],
              PRODUCTS[7],
              SPORT_PRODUCTS[1],
              PRODUCTS[6]
            ]}
          />
        )}

        {allProducts2 && allProducts2.length > 0 && (
          <SectionSliderProductCardNew
            data2={allProducts2}
            heading="Most Popular"
            subHeading="Best selling of the month"
          />
        )}

        {/*  */}
        {allProducts5.length > 0 && (
          <SectionSliderCategoriesNew
            data2={DATA2}
            data3={DATA2}
            heading={"Solutions By Industry"}
            cols={4}
          />
        )}

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div> */}

        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the Oz Cleaning blog">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <a href="/blog">
                <ButtonSecondary>Show all blog articles</ButtonSecondary>
              </a>
            </div>
          </div>
        </div> */}

        {/*  */}
        {/* <SectionClientSay /> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div> */}

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

        {/* SECTION */}
        {/* <SectionPromo3 /> */}

        {/* SECTION */}
        {/* <SectionGridFeatureItemsNew allProducts={allProducts} setcKey={setcKey} /> */}

        <div className="space-y-4 my-10">
          <p>
            At Oz Cleaning Supplies, we understand the importance of having the
            right tools to tackle any cleaning job effectively. Whether you're a
            professional cleaner or simply looking to keep your home spotless,
            we have everything you need to get the job done right. From
            multipurpose cleaners to specialty equipment, we carry a
            comprehensive selection of products from leading brands to ensure
            superior results every time.
          </p>

          <p>
            Located conveniently in Dandenong, our store is stocked with the
            latest innovations in cleaning technology to make your cleaning
            tasks easier and more efficient. Our knowledgeable staff is
            dedicated to providing personalized service and expert advice to
            help you find the perfect solutions for your specific needs.
          </p>

          <p>
            In addition to our extensive product range, we also offer
            competitive prices and convenient delivery options to make shopping
            with us hassle-free. Whether you prefer to shop in-store or online,
            you can count on Oz Cleaning Supplies for unbeatable value and
            exceptional service.
          </p>

          <p>
            Experience the difference that quality cleaning supplies can make in
            your home or business. Visit Oz Cleaning Supplies in Dandenong
            today, and let us help you achieve sparkling clean results every
            time!
          </p>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
