import React, { useEffect } from "react";
import { CurrentUser, getCart, getCartList, getMemberCart } from "../Services/API/Get";
import _ from "lodash";

const CartCount = async () => {
  const myCountOfCart = async () => {
    let Count = 0;
    
    await getCartList()
      .then((res) => {
        // console.log("get Cart >>>>>", res)
        let m = 0;
        const abc = res.data?.map((e) => {
          m = m + e.qty * e.discountedprice;
        });
        Count = res.data.length;

        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        Count = 0;

      });
    // console.log("Cart count >>>", Count);
    return Count;


  };

  return await myCountOfCart();
};

export default CartCount;
