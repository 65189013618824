import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logoFooter2.jpg";
import logoLightImg from "images/logo-light.svg";
import { getLogo } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  type?: any;
  sideMenu?: any;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
  type = "",
  sideMenu = false
}) => {
  const [logo, setLogo] = React.useState("");

  const logoGet = () => {
    getLogo()
      .then((res) => {
        // console.log(res.data);
        setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    logoGet();


  }, []);
  return (
    // <Link
    //   to="/"
    //   className={`ttnc-logo inline-block text-slate-600 ${className}`}
    // >
    <>
      {sideMenu ?
        <>

          {/* THIS USE FOR MY CLIENT */}
          {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
          { }
          {img ? (
            <img
              className={`block ${imgLight ? "dark:hidden" : ""
                } ${type === "footer" ? "max-h-16 sm:max-h-20" : "max-h-8 sm:max-h-10"}`}
              src={type === "footer" ? img : `${IMAGE_SERVER_URL_DEV}${logo}`}
              // src={img}
              alt="Logo"
            />
          ) : (
            "Logo Here"
          )}
          {imgLight && (
            <img
              className="hidden max-h-8 sm:max-h-10 dark:block"
              // src={imgLight}
              src={`${IMAGE_SERVER_URL_DEV}${logo}`}

              alt="Logo-Light"
            />
          )}

          {/* </Link> */}


        </>
        :
        <a href="/" className={`ttnc-logo inline-block text-slate-600 ${className}`}>
          {/* THIS USE FOR MY CLIENT */}
          {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
          { }
          {img ? (
            <div className="flex">
              <img
                className={`block ${imgLight ? "dark:hidden" : ""
                  } ${type === "footer" ? "max-h-16 sm:max-h-20" : "max-h-5 sm:max-h-8 xl:max-h-10"}`}
                src={type === "footer" ? img : `${IMAGE_SERVER_URL_DEV}${logo}`}
                // src={img}
                alt="Logo"
              />

              {/* <img
                className={`block ${imgLight ? "dark:hidden" : ""
                  } ${type === "footer" ? "max-h-16 sm:max-h-20" : "max-h-8 sm:max-h-10"}`}
                src={type === "footer" ? img : `${IMAGE_SERVER_URL_DEV}${logo}`}
                // src={img}
                alt="Logo"
              /> */}
            </div>


          ) : (
            "Logo Here"
          )}
          {imgLight && (
            <img
              className="hidden max-h-8 sm:max-h-10 dark:block"
              // src={imgLight}
              src={`${IMAGE_SERVER_URL_DEV}${logo}`}

              alt="Logo-Light"
            />
          )}

          {/* </Link> */}
        </a>


      }
    </>

  );
};

export default Logo;
