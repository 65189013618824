import React, { FC, useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo1";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import ModalPhotos from "./ModalPhotos";
import {
  Route,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import {
  getProductById,
  getProductByName,
  getProductBySizeAndColorId,
  getProductBySizeAndColorNew,
  getProductBySizeAndMaterial
} from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import _ from "lodash";
import parse from "html-react-parser";
import LoadingSpinner from "shared/LoadingSpinner";
import jwtDecode from "jwt-decode";
import { addToCard, addToWishlist } from "Services/API/Post";
import CartCount from "Constant/CartCount";
import CartContext from "Context/CartContext";
import { addToast } from "shared/Toast";
import UserWishList from "Constant/WishList";
import { Transition } from "@headlessui/react";
import { DeleteWishlist } from "Services/API/Delete";
import WishContext from "Context/WishContext";
import { upperFirst, lowerCase } from "lodash";
import { Helmet } from "react-helmet";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const { wish, setWish } = useContext(WishContext);

  const { cart, setCart } = useContext(CartContext);

  const { sizes, status, allOfSizes } = PRODUCTS[0];
  const { variants } = PRODUCTS[1];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [unitPrice, setunitPrice] = useState(0);
  const [productStock, setProductStock] = useState(
    // product.variation ? product.variation[0].size[0].stock : product.stock
    // product.availableStocks ? product.availableStocks : 0
    0
  );
  const [selectedProductId, setselectedProductId] = useState(0);
  const [selectedProductColor, setSelectedProductColor] = useState("");
  const [selectedProductPrice, setselectedProductPrice] = useState(0);
  const [selectedProduct, setselectedProduct] = React.useState<any | null>("");

  const [prodImages, setprodImages] = React.useState<any | null>([]);

  let proId: any = null;

  proId = useParams();

  proId = proId.id;

  // console.log("BOBO", proId);

  const [sProduct, setsProduct] = React.useState<any | null>("");
  const [pInfo, setpInfo] = React.useState<any | null>("");
  const [lopen, setlopen] = React.useState(true);

  const getThisProduct = (myyid: any) => {
    let productMyID = "";
    if (myyid === undefined) {
      productMyID = proId;
      if (productMyID === "undefined") {
        // this.props.history.goBack();
        window.scroll(0, 0);
        return;
      }
    } else {
      productMyID = myyid;
    }
    setlopen(true);
    getProductById(productMyID).then((res) => {
      setpInfo(res?.data[0]);
      getProduct(
        res?.data[0]?.productName,
        productMyID,
        res?.data[0].categoryName,
        res?.data[0]
      );
    });
  };

  const getProduct = (name: any, id: string, category: any, info: any) => {
    getProductByName({
      name: info.productId
    })
      .then((res) => {
        setlopen(false);
        let colorsof = [];
        let sizeOf = [];
        let imageOf = [];
        let tumblineOf = "";
        let imageOf2 = [];
        let materialOf = [];
        const mobile = _.uniqBy(res?.data, function (e: any) {
          return e.colour;
        });

        var mobile222 = mobile?.filter(function (el: any) {
          return el.colour !== "";
        });

        const mobile2 = _.uniqBy(res?.data, function (e: any) {
          return e.size;
        });

        var mobile22 = mobile2?.filter(function (el: any) {
          return el.size !== "";
        });

        const mobile3 = _.uniqBy(res?.data, function (e: any) {
          return e.meterial;
        });

        var mobile4 = mobile3?.filter(function (el: any) {
          return el.meterial !== "";
        });

        colorsof = mobile222.map((data: any, index) => {
          return {
            id: `colors${index}`,
            status: index === 0 ? true : false,
            color: `${data?.colour}`.trim().toString(),
            data: data
          };
        });

        sizeOf = mobile22.map((data: any, index) => {
          return {
            id: `size${index}`,
            status: index === 0 ? true : false,
            size: `${data?.size}`.trim().toString(),
            data: data
          };
        });

        // console.log("sizes>>>", sizeOf);
        // console.log("colours>>>", colorsof);

        materialOf = mobile4.map((data: any, index) => {
          return {
            id: `material${index}`,
            status: index === 0 ? true : false,
            material: `${data?.meterial}`.trim().toString(),
            data: data
          };
        });

        // console.log("JKJKJKJ", res?.data);
        const newArr = res?.data?.map((item: { images: string }) => {
          return {
            images: JSON.parse(item.images)
          };
        });

        imageOf = JSON.parse(res?.data[0].images);
        // console.log("JKJKJKJ22222", imageOf);

        var imgArr: { original: string; thumbnail: string }[] = [];

        const imageOf3 =
          imageOf.length > 0 &&
          imageOf?.map((item2: { path: any }) => {
            let obj = {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
            imgArr.push(obj);
            return {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
          });

        // imageOf2 = newArr?.map((item: { images: any[]; }) => {

        //   const imageOf3 = item.images.length > 0 && item.images?.map((item2: { path: any; }) => {
        //     let obj = {
        //       original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //       thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //     }
        //     imgArr.push(obj);
        //     return {
        //       original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //       thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //     };
        //   });

        //   return imageOf3;
        //   // return (
        //   //   <img
        //   //     src={`${IMAGE_SERVER_URL_DEV}${item.path}`}
        //   //     alt=""
        //   //     style={{
        //   //       objectFit: "contain",
        //   //     }}
        //   //   />
        //   // );
        // });
        if (imageOf)
          tumblineOf = imageOf?.filter(
            (item: { thumbnail: boolean }) => item?.thumbnail === true
          )[0];
        // }

        let def = Object.assign({}, info);

        setSizeSelected(sizeOf.length > 0 ? sizeOf[0].size : "none");
        setSelectedProductColor(
          colorsof.length > 0 ? colorsof[0].color : "none"
        );
        setprodImages(imgArr);

        def.variation = colorsof;
        def.sizes = sizeOf;
        def.nImages = imgArr;
        def.productId = proId;
        def.materials = materialOf;
        def.categoryName = category;
        def.thumbnail = tumblineOf;

        // console.log("BOBO<><>", def);

        setsProduct(def);

        // this.setState(
        //   {
        //     productData: res?.data[0],
        //     color: colorsof,
        //     size: sizeOf,
        //     render: true,
        //     image: imageOf2,
        //     discount: res?.data[0].discount,
        //   },
        //   () => {
        //     this.setState({
        //       showRealtedProduct: true,
        //     });
        //     this.getComments(id);
        //   }
        // );
      })
      .catch((error) => {
        setlopen(false);
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    getThisProduct(undefined);
  }, [proId]);

  const getProductPrice = () => {
    const myColor = sProduct.variation?.filter(
      (item: any) => item?.status === true
    );
    const mySize = sProduct.sizes?.filter((item: any) => item?.status === true);
    // console.log(sProduct?.productName);
    // setlopen(true);
    getProductBySizeAndColorId({
      productId: `${sProduct?.productId}`.trim().toString(),
      // color: `${selectedProductColor}`.trim().toString(),
      size: `${sizeSelected}`.trim().toString(),
      colour: `${selectedProductColor}`.trim().toString()
    })
      .then((data) => {
        setlopen(false);
        // console.log("price>>>>", data?.data[0]);

        let currProd = data?.data[0];
        setselectedProduct(data?.data[0]);
        setselectedProductPrice(data?.data[0]?.unitPrice);
        // setselectedDiscountPrice(data?.data[0]?.discountedPrice);
        setProductStock(currProd?.availableStocks);
        setselectedProductId(data?.data[0].id);
        setQualitySelected(1);
        // setpriceInfo(JSON.parse(data?.data[0]?.priceDetails))

        let unitPrice =
          JSON.parse(currProd.priceDetails).length > 0
            ? JSON.parse(currProd.priceDetails)[0].unitPrice
            : 0;
        let discount = currProd.discount;

        setunitPrice(currProd.unitPrice);

        let imageOf = [];

        imageOf = JSON.parse(data?.data[0].images);
        // console.log("JKJKJKJ22222", imageOf);

        var imgArr: { original: string; thumbnail: string }[] = [];

        const imageOf3 =
          imageOf.length > 0 &&
          imageOf?.map((item2: { path: any }) => {
            let obj = {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
            imgArr.push(obj);
            return {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
          });

        setprodImages(imgArr);

        // setdiscount(currProd.discount);
        // setfinalProductPrice(unitPrice?.toFixed(2));
        // setdiscountedPrice(parseFloat(unitPrice * discount / 100).toFixed(2))
        // setfinalDiscountedPrice(parseFloat(unitPrice * (100 - discount) / 100).toFixed(2))

        // if (data?.data[0]?.categoryName !== "Business Card & Letterhead" && data?.data[0]?.meterial === "Gloss/Matt") {
        //   setQuantityCount(50)
        // } else if (data?.data[0]?.categoryName !== "Business Card & Letterhead" && data?.data[0]?.meterial !== "Gloss/Matt") {
        //   setQuantityCount(25)
        // }
        // this.setState({
        //   unitPrice: data?.data[0]?.unit,
        //   discountedprice: data?.data[0]?.discountedPrice,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.log("error occur while fetching size", error);
        setProductStock(0);
        setunitPrice(0);
      });
  };

  React.useEffect(() => {
    // if (sizeSelected && selectedProductColor) {
    if (sProduct !== "") {
      getProductPrice();
    }
    // }
    // getCurrentUser();
  }, [sizeSelected, selectedProductColor, sProduct]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-rose-500";
    }
    if (Bgclass.includes("black")) {
      return "border-slate-900";
    }
    if (Bgclass.includes("gray")) {
      return "border-slate-900";
    }
    if (Bgclass.includes("yellow")) {
      return "border-amber-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("white")) {
      return "border-gray-400";
    }
    if (Bgclass.includes("pink")) {
      return "border-pink-400";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("brown")) {
      return "border-stone-800";
    }

    return "border-transparent";
  };

  const getBackgrounClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "bg-[#ef4444]";
    }
    if (Bgclass.includes("black")) {
      return "bg-[#334155]";
    }
    if (Bgclass.includes("gray")) {
      return "bg-[#9ca3af]";
    }
    if (Bgclass.includes("yellow")) {
      return "bg-[#fcd34d]";
    }
    if (Bgclass.includes("blue")) {
      return "bg-[#2563eb]";
    }
    if (Bgclass.includes("green")) {
      return "bg-[#22c55e]";
    }
    if (Bgclass.includes("white")) {
      return "bg-[#d6d3d1]";
    }
    if (Bgclass.includes("pink")) {
      return "bg-[#FFC0CB]";
    }

    if (Bgclass.includes("orange")) {
      return "bg-[#fb923c]";
    }
    if (Bgclass.includes("brown")) {
      return "bg-[#78350f]";
    }

    return "border-transparent";
  };

  const notifyAddToWish = () => {
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    // console.log("temp>>>>", tempId);

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id;
    }

    let error = false;
    let cartDetails = {
      productId: sProduct.id.toString(),
      userId: userId ? userId : tempId,
      qty: 1,
      // color: selectedProductColor,
      // size: selectedProductPrice,
      fileType: "",
      image: ""
    };

    // console.log("data>>>>", cartDetails);

    if (!_.map(wish, "id").includes(sProduct.id)) {
      addToWishlist(cartDetails)
        .then((data) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-sm font-semibold leading-none">
                  Added to WishList !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          // setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          // toast.custom(
          //   (t) => (
          //     <NotifyAddTocart
          //       productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`}
          //       qualitySelected={1}
          //       show={t.visible}
          //       sizeSelected={""}
          //       variantActive={variantActive}
          //       selectedProductColor={""}
          //       selectedProduct={{productName:productName,unitPrice:unitprice}}
          //     />
          //   ),
          //   { position: "top-right", id: "nc-product-notify", duration: 3000 }
          // );

          UserWishList().then((res) => {
            // console.log("count res-------->", res);
            setWish(res);
          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          // setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });
    } else {
      DeleteWishlist(
        wish.filter((item: any) => item?.id === sProduct?.id)[0]?.wishlistId
      )
        .then((res) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-sm font-semibold leading-none">
                  Removed from WishList !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          UserWishList().then((res) => {
            // console.log("count res-------->", res);
            setWish(res);
          });
          //  this.setState(
          //    {
          //      open_toast: true,
          //      alertClass: "error",
          //      msg: `${data.displayName} Removed`,
          //      color: "#635bff",
          //    },
          //    () => this.getAllCart()
          //  );
        })
        .catch((error) => console.error("delete cart item error", error));
    }
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`${IMAGE_SERVER_URL_DEV}${sProduct?.thumbnail?.path}`}
            alt={selectedProduct.productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-sm font-medium ">
                  {selectedProduct.productName}
                </h3>
                {/* <p className="mt-1 text-xs text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices price={selectedProduct.unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-xs">
            {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

            <div className="flex">
              <a href="/account-savelists">
                <button
                  type="button"
                  className="font-medium text-[#74BC1F] dark:text-primary-500 "
                >
                  View WishList
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    // console.log("temp>>>>", tempId);

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id;
    }

    let error = false;
    let cartDetails = {
      productId: selectedProductId.toString(),
      userId: userId ? userId : tempId,
      qty: qualitySelected,
      color: selectedProductColor,
      size: selectedProductPrice,
      fileType: "",
      image: ""
    };

    // console.log("data>>>>", cartDetails);

    addToCard(cartDetails)
      .then((data) => {
        setlopen(false);
        // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
        toast.custom(
          (t) => (
            <NotifyAddTocart
              productImage={`${IMAGE_SERVER_URL_DEV}${sProduct?.thumbnail?.path}`}
              qualitySelected={qualitySelected}
              show={t.visible}
              sizeSelected={sizeSelected}
              variantActive={variantActive}
              selectedProductColor={selectedProductColor}
              selectedProduct={selectedProduct}
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
        CartCount().then((res) => {
          // console.log("count res-------->", res);
          setCart(res);
        });
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} Added to Cart`,
        //   open_toast: true,
        // });
      })
      .catch((err) => {
        setlopen(false);
        addToast(`${err.response.data.message}`, "warning");
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  };
  // console.log(sProduct);
  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          {selectedProductColor !== "none" && (
            <span className="text-xs font-medium">
              Color:
              <span className="ml-1 font-semibold capitalize">
                {/* {variants[variantActive].name} */}
                {selectedProductColor}
              </span>
            </span>
          )}
        </label>
        {/* <div className="flex mt-3">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${variantActive === index
                ? "border-[#74BC1F] dark:border-primary-500"
                : "border-transparent"
                }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div> */}
        <div className="flex space-x-2 mt-3">
          {/* {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-9 h-9 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
                ? getBorderClass(variant.color)
                : "border-transparent"
                }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))} */}
          {sProduct?.variation?.map((variant: any, index: any) => (
            <div
              key={index}
              onClick={() => {
                setVariantActive(index);
                setSelectedProductColor(variant.color);
              }}
              className={`relative w-9 h-9 rounded-full overflow-hidden z-10 border cursor-pointer ${
                selectedProductColor === variant.color
                  ? getBorderClass(lowerCase(variant.color))
                  : "border-transparent"
              }`}
              title={variant.color}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${getBackgrounClass(
                  lowerCase(variant.color)
                )}`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-xs">
          {sizeSelected !== "none" && (
            <>
              <label htmlFor="">
                <span className="">
                  Size / Model Number:
                  <span className="ml-1 font-semibold">{sizeSelected}</span>
                </span>
              </label>
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="##"
                className="text-[#74BC1F] hover:text-primary-500"
              >
                See sizing chart
              </a> */}
            </>
          )}
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-4 gap-2 mt-3">
          {sProduct?.sizes?.map((size: any, index: any) => {
            const isActive = size.size === sizeSelected;
            // const sizeOutStock = !sizes.includes(size);
            const sizeOutStock = false;
            return (
              <div
                key={index}
                className={`relative rounded-lg border flex items-center justify-center 
                text-xs sm:text-xs uppercase font-bold select-none overflow-hidden z-0 py-3 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                  isActive
                    ? "bg-[#74BC1F] border-[#74BC1F] text-white hover:bg-[#74BC1F]"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size.size);
                }}
              >
                {size.size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-[10px] bg-red-600 dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-200 text-slate-900 dark:text-slate-300";
    // if (status === "Hot") {
    //   return (
    //     <div className={CLASSES}>
    //       <SparklesIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "50% Discount") {
    //   return (
    //     <div className={CLASSES}>
    //       <IconDiscount className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "Sold Out") {
    //   return (
    //     <div className={CLASSES}>
    //       <NoSymbolIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "limited edition") {
    //   return (
    //     <div className={CLASSES}>
    //       <ClockIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    if (sProduct?.latest === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Hot"}</span>
        </div>
      );
    }
    if (sProduct?.featured === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Popular"}</span>
        </div>
      );
    }
    if (sProduct?.callForPrice === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Call For Price"}</span>
        </div>
      );
    }
    return null;
  };

  const [pSku, setPSku] = useState(null);

  useEffect(() => {
    const getSkuByVar = () => {
     
      setPSku(selectedProduct.sku);
    };
    getSkuByVar();
  }, [selectedProduct]);
// console.log("sele", selectedProduct)
  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-xl sm:text-2xl font-semibold">
            {/* Heavy Weight Shoes */}
            {selectedProduct?.productName}
          </h2>

          <span className="flex mt-2">
            SKU :<span className="ml-1 font-semibold">{pSku !== 'undefined' ? pSku : ''}</span>
          </span>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-lg font-semibold">$112.00</div> */}
            {selectedProduct?.callForPrice != 1 && (
              <Prices
                contentClass="py-1 px-2 md:py-1.5 md:px-3 text-base font-semibold"
                price={selectedProduct?.unitPrice}
              />
            )}

            {(sProduct?.latest === 1 ||
              sProduct?.featured === 1 ||
              sProduct?.callForPrice === 1) && (
              <>
                {selectedProduct?.callForPrice != 1 && (
                  <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
                )}

                <div className="flex items-center">
                  {/* <a
    href="#reviews"
    className="flex items-center text-xs font-medium"
  >
    <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
    <div className="ml-1.5 flex">
      <span>4.9</span>
      <span className="block mx-2">·</span>
      <span className="text-slate-600 dark:text-slate-400 underline">
        142 reviews
      </span>
    </div>
  </a>
  <span className="hidden sm:block mx-2.5">·</span> */}
                  <div className="hidden sm:flex items-center text-xs">
                    <SparklesIcon className="w-3.5 h-3.5" />
                    {/* <span className="ml-1 leading-none">{status}</span> */}
                    <span className="ml-1 leading-none">
                      {sProduct?.latest === 1
                        ? "Hot"
                        : sProduct?.featured === 1
                        ? "Popular"
                        : sProduct?.callForPrice === 1
                        ? "Call For Price"
                        : ""}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}

        <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          {productStock !== 0 ? (
            <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
              <NcInputNumber
                max={productStock}
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </div>
          ) : (
            <></>
          )}

          {sProduct?.callForPrice === 1 ? (
            <ButtonPrimary
              disabled={productStock ? false : true}
              className={`flex-1 flex-shrink-0 ${
                !productStock &&
                "text-opacity-60 dark:text-opacity-60 cursor-not-allowed"
              }`}
              // onClick={notifyAddTocart}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">{"Call For Price"}</span>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              disabled={productStock ? false : true}
              className={`flex-1 flex-shrink-0 ${
                !productStock &&
                "text-opacity-60 dark:text-opacity-60 cursor-not-allowed"
              }`}
              onClick={notifyAddTocart}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">
                {productStock ? "Add to cart" : "Out of Stock"}
              </span>
            </ButtonPrimary>
          )}
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        {/* <AccordionInfo /> */}

        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">
          <Policy />
        </div>
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          {parse(selectedProduct.description)}
          {/* <p>
            The patented eighteen-inch hardwood Arrowhead deck --- finely
            mortised in, makes this the strongest and most rigid canoe ever
            built. You cannot buy a canoe that will afford greater satisfaction.
          </p>
          <p>
            The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
            1922. Wickett had previously worked for the Old Town Canoe Co from
            1900 to 1914. Manufacturing of the classic wooden canoes in Valley
            Park, Missouri ceased in 1978.
          </p>
          <ul>
            <li>Regular fit, mid-weight t-shirt</li>
            <li>Natural color, 100% premium combed organic cotton</li>
            <li>
              Quality cotton grown without the use of herbicides or pesticides -
              GOTS certified
            </li>
            <li>Soft touch water based printed in the USA</li>
          </ul> */}
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cleaning supplies Dandenong - Oz Cleaning Supplies</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className={`nc-ProductDetailPage ${className}`}>
        {/* MAIn */}
        <main className="container mt-5 lg:mt-11">
          <div className="lg:flex">
            {/* CONTENT */}
            <div className="w-full lg:w-[55%] ">
              {/* HEADING */}
              <div className="relative">
                <div className="aspect-w-16 aspect-h-8">
                  <img
                    src={prodImages[0]?.original}
                    className="w-full rounded-2xl object-contain"
                    alt="product detail 1"
                  />
                  {/* OVERLAY */}
                  {/* <span className="absolute inset-0 bg-[#9ec8a0] bg-opacity-10 transition-opacity"></span> */}
                  <div
                    className="absolute inset-0 bg-[#9ec8a0] bg-opacity-10 hover:bg-slate-900 hover:bg-opacity-20 hover:opacity-60 transition-opacity cursor-pointer rounded-2xl"
                    onClick={() => handleOpenModal(0)}
                  />
                </div>
                <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
                  onClick={() => handleOpenModal(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-xs font-medium">
                    Show all photos
                  </span>
                </div>
                {renderStatus()}
                {/* META FAVORITES */}

                <LikeButton
                  liked={_.map(wish, "id").includes(sProduct?.id)}
                  className="absolute right-3 top-3 "
                  addWish={notifyAddToWish}
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                {prodImages.length > 1 &&
                  [...prodImages].slice(1, 3).map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                      >
                        <img
                          src={item?.original}
                          className="w-full rounded-2xl object-cover"
                          alt="product detail 1"
                        />
                        {/* OVERLAY */}
                        <div
                          className="absolute inset-0 bg-slate-900 bg-opacity-20 opacity-0 hover:opacity-60 transition-opacity cursor-pointer"
                          onClick={() => handleOpenModal(index + 1)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* MODAL PHOTOS */}
            <ModalPhotos
              imgs={_.map(prodImages, "original")}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
              uniqueClassName="nc-ProductDetailPage2__modalPhotos"
            />

            {/* SIDEBAR */}
            <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
              {renderSectionContent()}
            </div>
          </div>

          {/* DETAIL AND REVIEW */}
          <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
            <div className="block xl:hidden">
              <Policy />
            </div>

            {selectedProduct.description && renderDetailSection()}

            <hr className="border-slate-200 dark:border-slate-700" />

            {/* {renderReviews()}

          <hr className="border-slate-200 dark:border-slate-700" /> */}

            {/* OTHER SECTION */}
            <SectionSliderProductCard
              heading="Customers also purchased"
              subHeading=""
              headingFontClassName="text-xl font-semibold"
              headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
              mainCategory={sProduct.mainId}
            />

            {/* SECTION */}
            {/* <div className="pb-20 xl:pb-28 lg:pt-14">
              <SectionPromo2 />
            </div> */}
          </div>
        </main>
        <div className="pt-12">
          <SectionPromo2 />
        </div>
        {/* MODAL VIEW ALL REVIEW */}
        <ModalViewAllReviews
          show={isOpenModalViewAllReviews}
          onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
        />
      </div>
    </>
  );
};

export default ProductDetailPage;
